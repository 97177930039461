// import React from "react";

// export const Navigation = (props) => {
//   const scrollToSection = (id) => {
//     const section = document.getElementById(id);
//     if (section) {
//       section.scrollIntoView({ behavior: "smooth" });
//     }
//   };

//   return (
//     <nav
//       id="menu"
//       className="navbar navbar-default navbar-fixed-top"
//       style={{
//         padding: "10px 0",
//         backgroundColor: "#2e8b57", // Green background color
//         color: "white",
//       }}
//     >
//       <div className="container-fluid">
//         <div className="navbar-header">
//           <button
//             type="button"
//             className="navbar-toggle collapsed"
//             data-toggle="collapse"
//             data-target="#bs-example-navbar-collapse-1"
//             style={{ borderColor: "white" }} // White border for toggle button
//           >
//             <span className="sr-only">Toggle navigation</span>
//             <span className="icon-bar" style={{ backgroundColor: "white" }}></span>
//             <span className="icon-bar" style={{ backgroundColor: "white" }}></span>
//             <span className="icon-bar" style={{ backgroundColor: "white" }}></span>
//           </button>
//           <a
//             className="navbar-brand page-scroll"
//             href="#page-top"
//             style={{ display: "flex", alignItems: "center", color: "white" }}
//           >
//             <img
//               src="/img/logo.png" 
//               alt="Company Logo"
//               style={{
//                 height: "50px",
//                 marginRight: "10px",
//                 borderRadius: "50%", 
//               }}
//             />
//             <span className="navbar-title" style={{ color: "#f0c419", fontSize: "20px", fontWeight: "bold" }}>Unique Kudos Petroleum</span>
//           </a>
//         </div>

//         <div
//           className="collapse navbar-collapse"
//           id="bs-example-navbar-collapse-1"
//           style={{ justifyContent: "flex-end" }}
//         >
//           <ul className="nav navbar-nav navbar-right" style={{ fontSize: "16px" }}>
//             <li><a onClick={() => scrollToSection("features")} className="page-scroll" style={{ color: "#f0c419", cursor: "pointer" }}>Features</a></li>
//             <li><a onClick={() => scrollToSection("about")} className="page-scroll" style={{ color: "#f0c419", cursor: "pointer" }}>About</a></li>
//             <li><a onClick={() => scrollToSection("services")} className="page-scroll" style={{ color: "#f0c419", cursor: "pointer" }}>Services</a></li>
//             <li><a onClick={() => scrollToSection("import-export")} className="page-scroll" style={{ color: "#f0c419", cursor: "pointer" }}>Products</a></li>
//             <li><a onClick={() => scrollToSection("gallery")} className="page-scroll" style={{ color: "#f0c419", cursor: "pointer" }}>Gallery</a></li>
//             <li><a onClick={() => scrollToSection("team")} className="page-scroll" style={{ color: "#f0c419", cursor: "pointer" }}>Team</a></li>
//             <li><a onClick={() => scrollToSection("contact")} className="page-scroll" style={{ color: "#f0c419", cursor: "pointer" }}>Contact</a></li>
//           </ul>
//         </div>
//       </div>
//     </nav>
//   );
// };





// import React from "react";
// import { useTranslation } from 'react-i18next';

// export const Navigation = (props) => {
//   const { i18n } = useTranslation(); // Import i18n from react-i18next

//   const scrollToSection = (id) => {
//     const section = document.getElementById(id);
//     if (section) {
//       section.scrollIntoView({ behavior: "smooth" });
//     }
//   };

//   const handleLanguageChange = (event) => {
//     i18n.changeLanguage(event.target.value); // Change language based on selection
//   };

//   return (
//     <nav
//       id="menu"
//       className="navbar navbar-default navbar-fixed-top"
//       style={{
//         padding: "10px 0",
//         backgroundColor: "#2e8b57", // Green background color
//         color: "white",
//       }}
//     >
//       <div className="container-fluid">
//         <div className="navbar-header">
//           <button
//             type="button"
//             className="navbar-toggle collapsed"
//             data-toggle="collapse"
//             data-target="#bs-example-navbar-collapse-1"
//             style={{ borderColor: "white" }} // White border for toggle button
//           >
//             <span className="sr-only">Toggle navigation</span>
//             <span className="icon-bar" style={{ backgroundColor: "white" }}></span>
//             <span className="icon-bar" style={{ backgroundColor: "white" }}></span>
//             <span className="icon-bar" style={{ backgroundColor: "white" }}></span>
//           </button>
//           <a
//             className="navbar-brand page-scroll"
//             href="#page-top"
//             style={{ display: "flex", alignItems: "center", color: "white" }}
//           >
//             <img
//               src="/img/logo.png" 
//               alt="Company Logo"
//               style={{
//                 height: "50px",
//                 marginRight: "10px",
//                 borderRadius: "50%", 
//               }}
//             />
//             <span className="navbar-title" style={{ color: "#f0c419", fontSize: "20px", fontWeight: "bold" }}>Unique Kudos Petroleum</span>
//           </a>
//         </div>

//         <div
//           className="collapse navbar-collapse"
//           id="bs-example-navbar-collapse-1"
//           style={{ justifyContent: "flex-end" }}
//         >
//           <ul className="nav navbar-nav navbar-right" style={{ fontSize: "16px", display: "flex", alignItems: "center" }}>
//             <li><a onClick={() => scrollToSection("features")} className="page-scroll" style={{ color: "#f0c419", cursor: "pointer" }}>Features</a></li>
//             <li><a onClick={() => scrollToSection("about")} className="page-scroll" style={{ color: "#f0c419", cursor: "pointer" }}>About</a></li>
//             <li><a onClick={() => scrollToSection("services")} className="page-scroll" style={{ color: "#f0c419", cursor: "pointer" }}>Services</a></li>
//             <li><a onClick={() => scrollToSection("import-export")} className="page-scroll" style={{ color: "#f0c419", cursor: "pointer" }}>Products</a></li>
//             <li><a onClick={() => scrollToSection("gallery")} className="page-scroll" style={{ color: "#f0c419", cursor: "pointer" }}>Gallery</a></li>
//             <li><a onClick={() => scrollToSection("team")} className="page-scroll" style={{ color: "#f0c419", cursor: "pointer" }}>Team</a></li>
//             <li><a onClick={() => scrollToSection("contact")} className="page-scroll" style={{ color: "#f0c419", cursor: "pointer" }}>Contact</a></li>

//             {/* Language Selector */}
//             <li>
//               <select
//                 onChange={handleLanguageChange}
//                 style={{ marginLeft: "10px", backgroundColor: "#2e8b57", color: "#f0c419", border: "none", borderRadius: "5px", padding: "5px" }}
//               >
//                 <option value="en">English</option>
//                 <option value="de">German</option>
//                 <option value="fr">French</option>
//                 <option value="vi">Vietnamese</option>
//                 <option value="zh">Chinese</option>
//                 <option value="ar">Arabic</option>
//                 <option value="fa">Persian</option>
//               </select>
//             </li>
//           </ul>
//         </div>
//       </div>
//     </nav>
//   );
// };





import React from "react";
import { useTranslation } from 'react-i18next';

export const Navigation = (props) => {
  const { t, i18n } = useTranslation(); // Use translation hook

  const scrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleLanguageChange = (event) => {
    i18n.changeLanguage(event.target.value); // Change language based on selection
  };

  return (
    <nav
      id="menu"
      className="navbar navbar-default navbar-fixed-top"
      style={{
        padding: "10px 0",
        backgroundColor: "#2e8b57",
        color: "white",
      }}
    >
      <div className="container-fluid">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
            style={{ borderColor: "white" }}
          >
            <span className="sr-only">Toggle navigation</span>
            <span className="icon-bar" style={{ backgroundColor: "white" }}></span>
            <span className="icon-bar" style={{ backgroundColor: "white" }}></span>
            <span className="icon-bar" style={{ backgroundColor: "white" }}></span>
          </button>
          <a
            className="navbar-brand page-scroll"
            href="#page-top"
            style={{ display: "flex", alignItems: "center", color: "white" }}
          >
            <img
              src="/img/logo.png"
              alt={t('companyLogoAlt')}
              style={{
                height: "50px",
                marginRight: "10px",
                borderRadius: "50%",
              }}
            />
            <span className="navbar-title" style={{ color: "#f0c419", fontSize: "20px", fontWeight: "bold" }}>
              {t('companyTitle')}
            </span>
          </a>
        </div>

        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
          style={{ justifyContent: "flex-end" }}
        >
          <ul className="nav navbar-nav navbar-right" style={{ fontSize: "16px", display: "flex", alignItems: "center" }}>
            <li><a onClick={() => scrollToSection("features")} className="page-scroll" style={{ color: "#f0c419", cursor: "pointer" }}>{t('featuresTitle')}</a></li>
            <li><a onClick={() => scrollToSection("about")} className="page-scroll" style={{ color: "#f0c419", cursor: "pointer" }}>{t('aboutTitle')}</a></li>
            <li><a onClick={() => scrollToSection("services")} className="page-scroll" style={{ color: "#f0c419", cursor: "pointer" }}>{t('servicesTitle')}</a></li>
            <li><a onClick={() => scrollToSection("import-export")} className="page-scroll" style={{ color: "#f0c419", cursor: "pointer" }}>{t('productsTitle')}</a></li>
            <li><a onClick={() => scrollToSection("gallery")} className="page-scroll" style={{ color: "#f0c419", cursor: "pointer" }}>{t('galleryTitle')}</a></li>
            <li><a onClick={() => scrollToSection("team")} className="page-scroll" style={{ color: "#f0c419", cursor: "pointer" }}>{t('teamTitle')}</a></li>
            <li><a onClick={() => scrollToSection("contact")} className="page-scroll" style={{ color: "#f0c419", cursor: "pointer" }}>{t('contactTitle')}</a></li>

            {/* Language Selector */}

            {/* <li>
              <select
                onChange={handleLanguageChange}
                style={{ marginLeft: "10px", backgroundColor: "#2e8b57", color: "#f0c419", border: "none", borderRadius: "5px", padding: "5px" }}
                aria-label={t('languageSelectorLabel')}
              >
                <option value="en">{t('english')}</option>
                <option value="de">{t('german')}</option>
                <option value="fr">{t('french')}</option>
                <option value="vi">{t('vietnamese')}</option>
                <option value="zh">{t('chinese')}</option>
                <option value="ar">{t('arabic')}</option>
                <option value="fa">{t('persian')}</option>
              </select>
            </li> */}
            <li style={{ position: 'relative', marginLeft: '10px' }}>
  <select
    onChange={handleLanguageChange}
    style={{
      backgroundColor: "#f0c419", // Gold background
      color: "#333", // Dark text color for contrast
      border: "none",
      borderRadius: "5px",
      padding: "10px 15px",
      fontSize: "16px",
      fontWeight: "bold",
      cursor: "pointer",
      transition: "background-color 0.3s ease",
      outline: "none",
    }}
    defaultValue="" // Set default value to show "Select language"
    aria-label={t('languageSelectorLabel')}
  >
    <option value="" disabled style={{ color: "#888" }}>Select language</option> {/* Placeholder option */}
    <option value="en">{t('english')}</option>
    <option value="de">{t('german')}</option>
    <option value="fr">{t('french')}</option>
    <option value="vi">{t('vietnamese')}</option>
    <option value="zh">{t('chinese')}</option>
    <option value="ar">{t('arabic')}</option>
    <option value="fa">{t('persian')}</option>
  </select>
</li>

          </ul>
        </div>
      </div>
    </nav>
  );
};
export default Navigation;





