import React from 'react';

const Petrochemical = () => {
  const containerStyle = {
    padding: "2rem",
    backgroundColor: "#f5f5f5",
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    maxWidth: "600px",
    margin: "20px auto",
    textAlign: "center",
  };

  const titleStyle = {
    fontSize: "28px",
    color: "#2e8b57",
    marginBottom: "1rem",
  };

  const itemStyle = {
    fontSize: "18px",
    color: "#333",
    margin: "0.5rem 0",
    padding: "10px",
    borderRadius: "5px",
    transition: "background 0.3s, transform 0.3s",
  };

  const itemHoverStyle = {
    backgroundColor: "#e0f7e0",
    transform: "scale(1.05)",
  };

  return (
    <div style={containerStyle}>
      <h2 style={titleStyle}>Petrochemical Products</h2>
      <div>
        {[
          "Xylene",
          "P-Xylene",
          "Urea",
          "White spirit",
          "HDPE",
          "MDPE",
          "LDPE",
          "UDPE",
          "PP (HOMO POLYMER /CO POLYMER /PIPE)",
          "SER",
          "HPS",
          "GPPS",
          "PVC",
          "PC",
          "ABS",
        ].map((item, index) => (
          <p
            key={index}
            style={{
              ...itemStyle,
              ...(index % 2 === 0 ? { backgroundColor: "#ffffff" } : { backgroundColor: "#f9f9f9" }),
              ...(index % 2 === 0 ? itemHoverStyle : {}),
            }}
            onMouseOver={(e) => {
              e.currentTarget.style.backgroundColor = "#e0f7e0"; // Change background color on hover
              e.currentTarget.style.transform = "scale(1.05)"; // Scale effect
            }}
            onMouseOut={(e) => {
              e.currentTarget.style.backgroundColor = index % 2 === 0 ? "#ffffff" : "#f9f9f9"; // Reset background color
              e.currentTarget.style.transform = "scale(1)"; // Reset scale effect
            }}
          >
            - {item}
          </p>
        ))}
      </div>
    </div>
  );
};

export default Petrochemical;
