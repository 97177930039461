// import React from "react";
// import { useNavigate } from "react-router-dom";

// export const ImportExport = (props) => {
//   const navigate = useNavigate();

//   const handleImageClick = (branch) => {
//     // Navigate to specific page based on the branch
//     navigate(`/${branch}`);
//   };

//   return (
//     <div id="import-export" style={{ padding: "60px 0", backgroundColor: "#f9f9f9" }}>
//       <div className="container">
//         <div className="section-title text-center">
//           <h2 style={{ fontSize: "3rem", fontWeight: "700", color: "darkgreen" }}>
//             Export and Import
//           </h2>
//           <p style={{ fontSize: "1.5rem", color: "darkgreen", marginTop: "10px" }}>
//             Our operations span across various branches in the industry.
//           </p>
//         </div>

//         {/* Branch rows */}
//         <div className="row" style={{ marginTop: "40px", justifyContent: "center" }}>
//           {props.data && props.data.map((d, i) => (
//             <div
//               key={`${d.name}-${i}`}
//               className="col-12 col-sm-6 col-md-4 col-lg-3" // Responsive columns
//               style={{
//                 display: "flex",
//                 justifyContent: "center",
//                 marginBottom: "30px",
//               }}
//             >
//               <div
//                 className="branch"
//                 style={{
//                   backgroundColor: "#fff",
//                   padding: "20px",
//                   borderRadius: "10px",
//                   boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
//                   textAlign: "center",
//                   width: "100%",
//                   maxWidth: "280px",
//                   height: "300px", // Set height for consistency
//                   display: "flex",
//                   flexDirection: "column",
//                   justifyContent: "space-between",
//                 }}
//                 onClick={() => handleImageClick(d.page)}
//               >
//                 <div className="branch-icon" style={{ marginBottom: "15px" }}>
//                   <img
//                     src={d.img}
//                     alt={d.name}
//                     style={{
//                       width: "100%",
//                       height: "130px",
//                       objectFit: "cover",
//                       borderRadius: "10px", // Optional: added rounded corners for images
//                     }}
//                   />
//                 </div>
//                 <div className="branch-content">
//                   <h3 style={{ fontSize: "2rem", color: "darkgreen", fontWeight: "800" }}>{d.name}</h3>
//                   <p style={{ fontSize: "1.3rem", color: "darkgreen", marginTop: "10px" }}>{d.description}</p>
//                 </div>
//               </div>
//             </div>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// };






import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next'; // Import the useTranslation hook

export const ImportExport = (props) => {
  const navigate = useNavigate();
  const { t } = useTranslation(); // Initialize the translation function

  const handleImageClick = (branch) => {
    navigate(`/${branch}`);
  };

  return (
    <div id="import-export" style={{ padding: "60px 0", backgroundColor: "#f9f9f9" }}>
      <div className="container">
        <div className="section-title text-center">
          <h2 style={{ fontSize: "3rem", fontWeight: "700", color: "darkgreen" }}>
            {t('exportImportTitle')} {/* Use translation */}
          </h2>
          <p style={{ fontSize: "1.5rem", color: "darkgreen", marginTop: "10px" }}>
            {t('exportImportDescription')} {/* Use translation */}
          </p>
        </div>

        {/* Branch rows */}
        <div className="row" style={{ marginTop: "40px", justifyContent: "center" }}>
          {props.data && props.data.map((d, i) => (
            <div
              key={`${d.name}-${i}`}
              className="col-12 col-sm-6 col-md-4 col-lg-3" // Responsive columns
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "30px",
              }}
            >
              <div
                className="branch"
                style={{
                  backgroundColor: "#fff",
                  padding: "20px",
                  borderRadius: "10px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  textAlign: "center",
                  width: "100%",
                  maxWidth: "280px",
                  height: "300px", // Set height for consistency
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
                onClick={() => handleImageClick(d.page)}
              >
                <div className="branch-icon" style={{ marginBottom: "15px" }}>
                  <img
                    src={d.img}
                    alt={d.name}
                    style={{
                      width: "100%",
                      height: "130px",
                      objectFit: "cover",
                      borderRadius: "10px", // Optional: added rounded corners for images
                    }}
                  />
                </div>
                <div className="branch-content">
                  <h3 style={{ fontSize: "2rem", color: "darkgreen", fontWeight: "800" }}>{t(d.name)}</h3> {/* Use translation */}
                  <p style={{ fontSize: "1.3rem", color: "darkgreen", marginTop: "10px" }}>{t(d.description)}</p> {/* Use translation */}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};









