import React from 'react'


const Investment = () => {
  return (
    <div>investment</div>
  )
}


 export default Investment;