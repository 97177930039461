import React from 'react';

const OilPage = () => {
  const containerStyle = {
    padding: "2rem",
    backgroundColor: "#f5f5f5",
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    maxWidth: "600px",
    margin: "20px auto",
    textAlign: "center",
  };

  const titleStyle = {
    fontSize: "28px",
    color: "#2e8b57", // Dark green for a professional look
    marginBottom: "1rem",
  };

  const itemStyle = {
    fontSize: "18px",
    color: "#333",
    margin: "0.5rem 0",
    padding: "10px",
    borderRadius: "5px",
    transition: "background 0.3s, transform 0.3s",
  };

  return (
    <div style={containerStyle}>
      <h2 style={titleStyle}>Oil Products</h2>
      <div>
        {[
          "Bitumen types",
          "Crude oil light oil and heavy oil",
          "Fuel oil (180,280,380)",
          "Gasoline",
          "Gas oil (10 ppm)",
          "Gas oil (1000 ppm)",
          "Base oil Types",
          "Engine oil",
          "Methanol",
          "Naphtha"
        ].map((item, index) => (
          <p
            key={index}
            style={{
              ...itemStyle,
              backgroundColor: index % 2 === 0 ? "#ffffff" : "#f9f9f9",
            }}
            onMouseOver={(e) => {
              e.currentTarget.style.backgroundColor = "#e0f7e0"; // Change background color on hover
              e.currentTarget.style.transform = "scale(1.05)"; // Scale effect on hover
            }}
            onMouseOut={(e) => {
              e.currentTarget.style.backgroundColor = index % 2 === 0 ? "#ffffff" : "#f9f9f9"; // Reset background
              e.currentTarget.style.transform = "scale(1)"; // Reset scale effect
            }}
          >
            - {item}
          </p>
        ))}
      </div>
    </div>
  );
};

export default OilPage;
