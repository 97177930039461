// import React from "react";

// export const Team = (props) => {
//   return (
//     <div id="team" style={{ textAlign: "center", padding: "2rem 0" }}>
//       <div
//         className="container"
//         style={{
//           display: "flex",
//           flexDirection: "column", // Stack the title and description on top
//           alignItems: "center", // Center align the container
//         }}
//       >
//         <div
//           className="section-title"
//           style={{
//             textAlign: "center",
//             marginBottom: "2rem", // Optional: space below the title
//             width: "100%", // Ensure it takes the full width
//             display: "flex", // Use flex to center the content
//             flexDirection: "column",
//             alignItems: "center",
//             color: "#2e8b57", // Dark green color for all text in this section
//           }}
//         >
// <h2 style={{ margin: 0, color: "darkgreen" }}>Meet the Team</h2>
// <p style={{ margin: "1rem 0 0 0" }}>
//             "Meet our dedicated team, bringing expertise, innovation, and a
//             shared commitment to excellence in every endeavor."
//           </p>
//         </div>
//         <div
//           id="row"
//           style={{
//             display: "flex",
//             flexWrap: "wrap",
//             justifyContent: "center", // Center align the team members
//             gap: "2rem", // Space between the team members
//             width: "100%", // Ensure the row takes the full width
//             maxWidth: "1200px", // Optional: set a max width for larger screens
//           }}
//         >
//           {props.data
//             ? props.data.map((d, i) => (
//                 <div
//                   key={`${d.name}-${i}`}
//                   className="col-md-3 col-sm-6 team"
//                   style={{
//                     maxWidth: "280px",
//                     textAlign: "center",
//                     transition: "transform 0.3s ease, box-shadow 0.3s ease",
//                   }}
//                   onMouseEnter={(e) => {
//                     e.currentTarget.style.transform = "translateY(-10px)";
//                     e.currentTarget.style.boxShadow =
//                       "0 8px 16px rgba(0, 0, 0, 0.2)";
//                   }}
//                   onMouseLeave={(e) => {
//                     e.currentTarget.style.transform = "translateY(0)";
//                     e.currentTarget.style.boxShadow = "none";
//                   }}
//                 >
//                   <div
//                     className="thumbnail"
//                     style={{
//                       position: "relative",
//                       overflow: "hidden",
//                       borderRadius: "10px",
//                       boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
//                     }}
//                   >
//                     <img
//                       src={d.img}
//                       alt="..."
//                       className="team-img"
//                       style={{
//                         width: "100%",
//                         height: "350px", // Fixed height for uniformity
//                         objectFit: "cover", // Ensure the image covers the area
//                         borderRadius: "10px",
//                         transition: "transform 0.3s ease",
//                       }}
//                       onMouseEnter={(e) =>
//                         (e.currentTarget.style.transform = "scale(1.05)")
//                       }
//                       onMouseLeave={(e) =>
//                         (e.currentTarget.style.transform = "scale(1)")
//                       }
//                     />
//                     {/* <div className="caption">
//                       <h4 style={{
//                         fontSize: "1.5rem",
//                         fontWeight: "bold",
//                         marginTop: "1rem",
//                         color: "#333"
//                       }}>{d.name}</h4>
//                       <p style={{
//                         fontSize: "1.25rem",
//                         color: "#777"
//                       }}>{d.job}</p>
//                        <p style={{
//                         fontSize: "1.25rem",
//                         color: "#888"
//                       }}>{d.phone}</p>
//                        <p style={{
//                         fontSize: "1.25rem",
//                         color: "#888"
//                       }}>{d.email}</p>
//                     </div> */}
//                     <div
//                       className="caption"
//                       style={{ textAlign: "center", padding: "1rem 0" }}
//                     >
//                       <h4
//                         style={{
//                           fontSize: "1.5rem",
//                           fontWeight: "bold",
//                           marginTop: "1rem",
//                           color: "#2e8b57", // Dark green color for the name
//                         }}
//                       >
//                         {d.name}
//                       </h4>
//                       <p
//                         style={{
//                           fontSize: "1.25rem",
//                           color: "#4CAF50", // Lighter green for job title
//                           margin: "0.5rem 0",
//                         }}
//                       >
//                         {d.job}
//                       </p>
//                       <p
//                         style={{
//                           fontSize: "1.1rem",
//                           color: "#388E3C", // Darker green for contact details
//                           margin: "0.3rem 0",
//                         }}
//                       >
//                         {d.phone}
//                       </p>
//                       <p
//                         style={{
//                           fontSize: "1.1rem",
//                           color: "#388E3C", // Darker green for contact details
//                           margin: "0.3rem 0",
//                         }}
//                       >
//                         {d.email}
//                       </p>
//                     </div>
//                   </div>
//                 </div>
//               ))
//             : "loading"}
//         </div>
//       </div>
//     </div>
//   );
// };





// import React from "react";
// import { useTranslation } from 'react-i18next';

// export const Team = (props) => {
//   const { t } = useTranslation(); // Translation hook

//   return (
//     <div id="team" style={{ textAlign: "center", padding: "2rem 0" }}>
//       <div className="container" style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
//         <div className="section-title" style={{ textAlign: "center", marginBottom: "2rem", width: "100%", display: "flex", flexDirection: "column", alignItems: "center", color: "#2e8b57" }}>
//           <h2 style={{ margin: 0, color: "darkgreen" }}>{t('meetTheTeamTitle')}</h2>
//           <p style={{ margin: "1rem 0 0 0" }}>{t('meetTheTeamDescription')}</p>
//         </div>
//         <div id="row" style={{ display: "flex", flexWrap: "wrap", justifyContent: "center", gap: "2rem", width: "100%", maxWidth: "1200px" }}>
//           {props.data ? props.data.map((d, i) => (
//             <div key={`${d.name}-${i}`} className="col-md-3 col-sm-6 team" style={{ maxWidth: "280px", textAlign: "center", transition: "transform 0.3s ease, box-shadow 0.3s ease" }} onMouseEnter={(e) => {
//               e.currentTarget.style.transform = "translateY(-10px)";
//               e.currentTarget.style.boxShadow = "0 8px 16px rgba(0, 0, 0, 0.2)";
//             }} onMouseLeave={(e) => {
//               e.currentTarget.style.transform = "translateY(0)";
//               e.currentTarget.style.boxShadow = "none";
//             }}>
//               <div className="thumbnail" style={{ position: "relative", overflow: "hidden", borderRadius: "10px", boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)" }}>
//                 <img src={d.img} alt={t('teamMemberAlt', { name: d.name })} className="team-img" style={{ width: "100%", height: "350px", objectFit: "cover", borderRadius: "10px", transition: "transform 0.3s ease" }} onMouseEnter={(e) => (e.currentTarget.style.transform = "scale(1.05)")} onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")} />

//                 <div className="caption" style={{ textAlign: "center", padding: "1rem 0" }}>
//                   <h4 style={{ fontSize: "1.5rem", fontWeight: "bold", marginTop: "1rem", color: "#2e8b57" }}>
//                     {t('teamMemberAlt', { name: d.name })}
//                   </h4>
//                   <p style={{ fontSize: "1.25rem", color: "#4CAF50", margin: "0.5rem 0" }}>
//                     {t('teamMemberJob', { job: d.job })}
//                   </p>
//                   <p style={{ fontSize: "1.1rem", color: "#388E3C", margin: "0.3rem 0" }}>
//                     {t('teamMemberPhone', { phone: d.phone })}
//                   </p>
//                   <p style={{ fontSize: "1.1rem", color: "#388E3C", margin: "0.3rem 0" }}>
//                     {t('teamMemberEmail', { email: d.email })}
//                   </p>
//                 </div>
//               </div>
//             </div>
//           )) : t('loading')}
//         </div>
//       </div>
//     </div>
//   );
// };






import React from "react";
import { useTranslation } from 'react-i18next';

export const Team = (props) => {
  const { t } = useTranslation(); // Translation hook

  return (
    <div id="team" style={{ textAlign: "center", padding: "2rem 0" }}>
      <div className="container" style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        <div className="section-title" style={{ textAlign: "center", marginBottom: "2rem", width: "100%", display: "flex", flexDirection: "column", alignItems: "center", color: "#2e8b57" }}>
          <h2 style={{ margin: 0, color: "darkgreen" }}>{t('meetTheTeamTitle')}</h2>
          <p style={{ margin: "1rem 0 0 0" }}>{t('meetTheTeamDescription')}</p>
        </div>
        <div id="row" style={{ display: "flex", flexWrap: "wrap", justifyContent: "center", gap: "2rem", width: "100%", maxWidth: "1200px" }}>
          {props.data ? props.data.map((d, i) => (
            <div key={`${d.name}-${i}`} className="col-md-3 col-sm-6 team" style={{ maxWidth: "280px", textAlign: "center", transition: "transform 0.3s ease, box-shadow 0.3s ease" }} onMouseEnter={(e) => {
              e.currentTarget.style.transform = "translateY(-10px)";
              e.currentTarget.style.boxShadow = "0 8px 16px rgba(0, 0, 0, 0.2)";
            }} onMouseLeave={(e) => {
              e.currentTarget.style.transform = "translateY(0)";
              e.currentTarget.style.boxShadow = "none";
            }}>
              <div className="thumbnail" style={{ position: "relative", overflow: "hidden", borderRadius: "10px", boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)" }}>
                <img src={d.img} alt={t('teamMemberAlt')[d.name]} className="team-img" style={{ width: "100%", height: "350px", objectFit: "cover", borderRadius: "10px", transition: "transform 0.3s ease" }} onMouseEnter={(e) => (e.currentTarget.style.transform = "scale(1.05)")} onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")} />

                <div className="caption" style={{ textAlign: "center", padding: "1rem 0" }}>
                  <h4 style={{ fontSize: "1.5rem", fontWeight: "bold", marginTop: "1rem", color: "#2e8b57" }}>
                    {t(`teamMemberAlt.${d.name}`)} {/* Access the name directly */}
                  </h4>
                  <p style={{ fontSize: "1.25rem", color: "#4CAF50", margin: "0.5rem 0" }}>
                    {t(`teamMemberJob.${d.job}`)} {/* Access the job title directly */}
                  </p>
                  <p style={{ fontSize: "1.1rem", color: "#388E3C", margin: "0.3rem 0" }}>
                    {t('teamMemberPhone', { phone: d.phone })} {/* Phone translation */}
                  </p>
                  <p style={{ fontSize: "1.1rem", color: "#388E3C", margin: "0.3rem 0" }}>
                    {t('teamMemberEmail', { email: d.email })} {/* Email translation */}
                  </p>
                </div>
              </div>
            </div>
          )) : t('loading')}
        </div>
      </div>
    </div>
  );
};
