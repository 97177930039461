import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next'; // Import the useTranslation hook

// Modal Component
const Modal = ({ isOpen, onClose, title, content, imgSrc }) => {

  const { t } = useTranslation(); 
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden"; // Disable scrolling
    } else {
      document.body.style.overflow = "auto"; // Enable scrolling
    }

    return () => {
      document.body.style.overflow = "auto"; // Cleanup to enable scrolling
    };
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div style={modalOverlayStyle} onClick={onClose}>
      <div style={modalStyle} onClick={(e) => e.stopPropagation()}>
        <img src={imgSrc} alt={title} style={modalImageStyle} />
        <h3 style={modalTitleStyle}>{title}</h3> {/* Displaying title */}
        <p style={modalContentStyle}>{content}</p> {/* Displaying content */}
        <button onClick={onClose} style={closeButtonStyle}>
          {t('close')} 
        </button>
      </div>
    </div>
  );
};

// Modal styles
const modalOverlayStyle = {
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "rgba(0, 0, 0, 0.8)", // Darker overlay
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 1000, // Ensure the overlay is on top of everything
};

const modalStyle = {
  backgroundColor: "#fff",
  padding: "20px",
  borderRadius: "12px",
  width: "500px",
  boxShadow: "0 4px 20px rgba(0, 0, 0, 0.3)",
  textAlign: "center",
  zIndex: 1001,
};

const modalImageStyle = {
  width: "100%",
  height: "auto",
  borderRadius: "12px",
  marginBottom: "15px",
};

const modalTitleStyle = {
  color: "#d4af37",
  fontSize: "24px",
  margin: "10px 0",
};

const modalContentStyle = {
  color: "#333",
  fontSize: "16px",
  margin: "10px 0",
};

const closeButtonStyle = {
  marginTop: "10px",
  background: "#FFD700",
  border: "none",
  borderRadius: "5px",
  padding: "10px 15px",
  cursor: "pointer",
  color: "black",
  fontSize: "16px",
};

// Main Services Component
export const Services = (props) => {
  const { t } = useTranslation(); 

  return (
    <div
      id="services"
      className="text-center"
      style={{
        background: "radial-gradient(circle 878px at 52.2% 62.3%, rgba(20, 156, 73, 1) 0%, rgba(8, 31, 26, 1) 90%)",
        padding: "50px 0",
      }}
    >
      <div className="container">
        <div className="section-title">
          <h2 style={{ color: "#FFD700" }}>{t('ourServicesTitle')}</h2> {/* Use translation */}
          <p style={{ color: "#d4af37" }}>
            {t('ourServicesDescription')} {/* Use translation */}
          </p>
        </div>
      
        <div className="row">
          {props.data
            ? props.data.map((d, i) => <ServiceCard key={`${d.name}-${i}`} service={d} />)
            : t('loading')} {/* Use translation for loading text */}
        </div>
      </div>
    </div>
  );
};




// const ServiceCard = ({ service }) => {
//   const { imgSrc, name, text } = service;
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const { t } = useTranslation(); // Call useTranslation at the top level

//   return (
//     <div className="col-md-4">
//       <img
//         src={imgSrc}
//         alt={name}
//         style={{
//           width: "150px",
//           height: "auto",
//           objectFit: "cover",
//           borderRadius: "50%",
//         }}
//       />
//       <div className="service-desc">
//         <h3 style={{ color: "#d4af37" }}>{name}</h3>
//         <p style={{ color: "#d4af37" }}>
//           {text.length > 100 ? `${text.slice(0, 100)}...` : text}
//         </p>
//         {text.length > 100 && (
//           <button 
//             onClick={() => setIsModalOpen(true)} 
//             style={{ 
//               color: "#FFD700", 
//               background: "none", 
//               border: "none", 
//               cursor: "pointer", 
//               padding: 0,
//               textDecoration: "underline"
//             }}
//           >
//             {t('readMore')} {/* Use translation */}
//           </button>
//         )}
//       </div>

//       <Modal 
//         isOpen={isModalOpen} 
//         onClose={() => setIsModalOpen(false)} 
//         title={name} // Ensure the name is passed as title
//         content={text} // Ensure the full text is passed as content
//         imgSrc={imgSrc} // Pass the image source to the Modal
//       />
//     </div>
//   );
// };



const ServiceCard = ({ service }) => {
  const { imgSrc, name, text } = service; // `name` and `text` are translation keys
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t } = useTranslation(); // Translation hook

  return (

    // <div className="col-md-4">
    //   <img
    //     src={imgSrc}
    //     alt={t(name)} // Use translation for the name
    //     style={{
    //       width: "150px",
    //       height: "auto",
    //       objectFit: "cover",
    //       borderRadius: "50%",
    //     }}
    //   />
    //   <div className="service-desc">
    //     <h3 style={{ color: "#d4af37" }}>{t(name)}</h3> {/* Use translation */}
    //     <p style={{ color: "#d4af37" }}>
    //       {t(text).length > 100 ? `${t(text).slice(0, 100)}...` : t(text)} {/* Use translation */}
    //     </p>
    //     {t(text).length > 100 && (
    //       <button 
    //         onClick={() => setIsModalOpen(true)} 
    //         style={{ 
    //           color: "#FFD700", 
    //           background: "none", 
    //           border: "none", 
    //           cursor: "pointer", 
    //           padding: 0,
    //           textDecoration: "underline"
    //         }}
    //       >
    //         {t('readMore')} {/* Use translation */}
    //       </button>
    //     )}
    //   </div>

    //   <Modal 
    //     isOpen={isModalOpen} 
    //     onClose={() => setIsModalOpen(false)} 
    //     title={t(name)} // Pass translated name as title
    //     content={t(text)} // Pass translated text as content
    //     imgSrc={imgSrc} // Pass image source to Modal
    //   />
    // </div>
<div className="col-md-4">
    <img
        src={imgSrc}
        alt={t(name)} // Use translation for the name
        style={{
            width: "150px",
            height: "auto",
            objectFit: "cover",
            borderRadius: "50%",
        }}
    />
    <div className="service-desc">
        <h3 style={{ color: "#d4af37" }}>{t(name)}</h3> {/* Use translation */}
        <p style={{ color: "#d4af37" }}>
            {t(text).length > 50 ? `${t(text).slice(0, 50)}...` : t(text)} {/* Use translation */}
        </p>
        {t(text).length > 50 && (
            <button 
                onClick={() => setIsModalOpen(true)} 
                style={{ 
                    color: "#FFD700", 
                    background: "none", 
                    border: "none", 
                    cursor: "pointer", 
                    padding: 0,
                    textDecoration: "underline"
                }}
            >
                {t('readMore')} {/* Use translation */}
            </button>
        )}
    </div>

    <Modal 
        isOpen={isModalOpen} 
        onClose={() => setIsModalOpen(false)} 
        title={t(name)} // Pass translated name as title
        content={t(text)} // Pass translated text as content
        imgSrc={imgSrc} // Pass image source to Modal
    />
</div>

  );
};



