// import React from "react";

// export const Features = (props) => {
//   return (
//     <div id="features" className="text-center">
//       <div className="container">
//         <div className="col-md-10 col-md-offset-1 section-title" style={{ paddingTop: "5%" }}>
//           <h2 style={{ color: "#006400" }}>Features</h2> {/* Dark green hex color */}
//         </div>

//         <div className="row justify-content-center"> {/* Centering the rows */}
//           {props.data
//             ? props.data.map((d, i) => (
//                 <div key={`${d.title}-${i}`} className="col-xs-12 col-sm-6 col-md-4 col-lg-3 text-center" style={{ marginBottom: "30px" }}>
//                   <i
//                     className={d.icon}
//                     style={{
//                       fontSize: "3rem",
//                       background: "radial-gradient(circle 878px at 52.2% 62.3%, rgba(20, 156, 73, 1) 0%, rgba(8, 31, 26, 1) 90%)",
//                       color: "#fff",
//                       borderRadius: "50%",
//                       display: "inline-block",
//                       marginBottom: "10px",
//                     }}
//                   ></i>

//                   <h3 style={{ 
//                       fontWeight: "bold", 
//                       fontSize: "1.5rem", /* Adjusted for responsiveness */
//                       marginTop: "10px", 
//                       color: "#2e8b57" 
//                   }}>
//                     {d.title}
//                   </h3>
//                   <p style={{ 
//                       paddingBottom: "20%", /* Adjust padding for responsive spacing */
//                       fontSize: "1.2rem", /* Adjusted for responsiveness */
//                       color: "#2e8b57"
//                   }}>
//                     {d.text}
//                   </p>
//                 </div>
//               ))
//             : "Loading..."}
//         </div>
//       </div>
//     </div>
//   );
// };




import React from "react";
import { useTranslation } from 'react-i18next'; // Import the useTranslation hook

export const Features = (props) => {
  const { t } = useTranslation(); // Initialize the translation function

  return (
    <div id="features" className="text-center">
      <div className="container">
        <div className="col-md-10 col-md-offset-1 section-title" style={{ paddingTop: "5%" }}>
          <h2 style={{ color: "#006400" }}>{t('featuresTitle')}</h2> {/* Use translation for title */}
        </div>

        <div className="row justify-content-center"> {/* Centering the rows */}
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.title}-${i}`} className="col-xs-12 col-sm-6 col-md-4 col-lg-3 text-center" style={{ marginBottom: "30px" }}>
                  <i
                    className={d.icon}
                    style={{
                      fontSize: "3rem",
                      background: "radial-gradient(circle 878px at 52.2% 62.3%, rgba(20, 156, 73, 1) 0%, rgba(8, 31, 26, 1) 90%)",
                      color: "#fff",
                      borderRadius: "50%",
                      display: "inline-block",
                      marginBottom: "10px",
                    }}
                  ></i>

                  <h3 style={{ 
                      fontWeight: "bold", 
                      fontSize: "1.5rem", /* Adjusted for responsiveness */
                      marginTop: "10px", 
                      color: "#2e8b57" 
                  }}>
                    {t(d.title)} {/* Use translation for title */}
                  </h3>
                  <p style={{ 
                      paddingBottom: "20%", /* Adjust padding for responsive spacing */
                      fontSize: "1.2rem", /* Adjusted for responsiveness */
                      color: "#2e8b57"
                  }}>
                    {t(d.text)} {/* Use translation for text */}
                  </p>
                </div>
              ))
            : "Loading..."}
        </div>
      </div>
    </div>
  );
};
