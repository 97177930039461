import React from 'react';

const GasPage = () => {
  const containerStyle = {
    padding: "2rem",
    backgroundColor: "#f5f5f5",
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    maxWidth: "600px",
    margin: "20px auto",
    textAlign: "center",
  };

  const titleStyle = {
    fontSize: "28px",
    color: "#2e8b57", // Dark green for a professional look
    marginBottom: "1rem",
  };

  const subTitleStyle = {
    fontSize: "22px",
    color: "#333",
    margin: "1rem 0",
    padding: "10px",
    borderRadius: "5px",
    backgroundColor: "#ffffff",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
  };

  return (
    <div style={containerStyle}>
      <h2 style={titleStyle}>Gas Products</h2>
      <div style={subTitleStyle}>
        <h3>LPG</h3>
      </div>
    </div>
  );
};

export default GasPage;
