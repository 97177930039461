// import React from "react";

// export const About = (props) => {
//   return (
//     <div id="about">
//       <div className="container">
//         <div className="row">
//           <div className="col-xs-12 col-md-6">
//             <img src="img/about.jpg" className="img-responsive" alt="" />
//           </div>
//           <div className="col-xs-12 col-md-6">
//             <div className="about-text">
//               <h2 style={{ color: "darkgreen" }}>About Us</h2>
//               <p style={{ color: "#2e8b57" }}> {/* Lighter green color */}
//                 {props.data ? props.data.paragraph : "loading..."}
//               </p>
//               <h3 style={{ color: "darkgreen" }}>Why Choose Us?</h3>
//               <div className="list-style">
//                 <div className="col-lg-6 col-sm-6 col-xs-12">
//                   <ul>
//                     {props.data
//                       ? props.data.Why.map((d, i) => (
//                           <li key={`${d}-${i}`} style={{ color: "#2e8b57" }}> {/* Lighter green color */}
//                             {d}
//                           </li>
//                         ))
//                       : "loading"}
//                   </ul>
//                 </div>
//                 <div className="col-lg-6 col-sm-6 col-xs-12">
//                   <ul>
//                     {props.data
//                       ? props.data.Why2.map((d, i) => (
//                           <li key={`${d}-${i}`} style={{ color: "#2e8b57" }}> {/* Lighter green color */}
//                             {d}
//                           </li>
//                         ))
//                       : "loading"}
//                   </ul>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };






import React from "react";
import { useTranslation } from 'react-i18next';

export const About = (props) => {
  const { t } = useTranslation(); // Hook to access translation function

  return (
    <div id="about">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            <img src="img/about.jpg" className="img-responsive" alt="" />
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <h2 style={{ color: "darkgreen" }}>{t('AboutUsTitle')}</h2>
              <p style={{ color: "#2e8b57" }}>
                {t('AboutUsParagraph')}
              </p>
              <h3 style={{ color: "darkgreen" }}>{t('WhyChooseUsTitle')}</h3>
              <div className="list-style">
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    {t('WhyChooseUsList', { returnObjects: true }).map((item, i) => (
                      <li key={`${item}-${i}`} style={{ color: "#2e8b57" }}>
                        {item}
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    {t('WhyChooseUsList2', { returnObjects: true }).map((item, i) => (
                      <li key={`${item}-${i}`} style={{ color: "#2e8b57" }}>
                        {item}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
