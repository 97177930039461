// import { useState } from "react";
// import emailjs from "emailjs-com";
// import React from "react";

// const initialState = {
//   name: "",
//   email: "",
//   message: "",
// };

// export const Contact = (props) => {
//   const [{ name, email, message }, setState] = useState(initialState);
//   const [feedback, setFeedback] = useState(""); // State to show feedback message

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setState((prevState) => ({ ...prevState, [name]: value }));
//   };

//   const clearState = () => setState({ ...initialState });

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     setFeedback(""); // Reset feedback message on submission

//     // Send the email using EmailJS
//     emailjs
//       .send(
//         "service_xqzvbw5", // Correct Service ID
//         "template_hao0qy9", // Correct Template ID
//         {
//           from_name: name,
//           from_email: email,
//           to_email: "ceo.barakzai@ukpetroleum.co.uk", // Replace with recipient's email
//           message: message,
//         },
//         "IPK2zT8ebCC1eW4AK" // Correct User ID
//       )
//       .then(
//         (response) => {
//           setFeedback("Email sent successfully!"); // Success message
//           clearState(); // Clear the form fields after successful submission
//         },
//         (error) => {
//           setFeedback("Failed to send email. Please try again."); // Error message
//           console.error("Email sending error:", error.text);
//         }
//       );
//   };

//   return (
//     <div>
//       <div
//         id="contact"
//         style={{
//           // background: "linear-gradient(to right, #d05d00, #6b2200)",
//           background: "radial-gradient(circle 878px at 52.2% 62.3%, rgba(20, 156, 73, 1) 0%, rgba(8, 31, 26, 1) 90%)",

//           color: "#ffffff",
//           padding: "50px 0",
//         }}
//       >
//         <div className="container">
//           <div className="col-md-8">
//             <div className="row">
//               <div className="section-title">
//                 <h2>Get In Touch</h2>
//                 <p>
//                   Please fill out the form below to send us an email, and we will
//                   get back to you as soon as possible.
//                 </p>
//               </div>
//               {feedback && <p style={{ color: "#fff" }}>{feedback}</p>} {/* Feedback Message */}
//               <form name="sentMessage" onSubmit={handleSubmit}>
//                 <div className="row">
//                   <div className="col-md-6">
//                     <div className="form-group">
//                       <input
//                         type="text"
//                         id="name"
//                         name="name"
//                         className="form-control"
//                         placeholder="Name"
//                         required
//                         onChange={handleChange}
//                         value={name}
//                       />
//                     </div>
//                   </div>
//                   <div className="col-md-6">
//                     <div className="form-group">
//                       <input
//                         type="email"
//                         id="email"
//                         name="email"
//                         className="form-control"
//                         placeholder="Email"
//                         required
//                         onChange={handleChange}
//                         value={email}
//                       />
//                     </div>
//                   </div>
//                 </div>
//                 <div className="form-group">
//                   <textarea
//                     name="message"
//                     id="message"
//                     className="form-control"
//                     rows="4"
//                     placeholder="Message"
//                     required
//                     onChange={handleChange}
//                     value={message}
//                   ></textarea>
//                 </div>
//                 <button type="submit" className="btn btn-custom btn-lg">
//                   Send Message
//                 </button>
//               </form>
//             </div>
//           </div>
//           <div className="col-md-3 col-md-offset-1 contact-info">
//             <div className="contact-item">
//               <h3>Contact Info</h3>
//               <p>
//                 <span>
//                   <i className="fa fa-map-marker"></i> Address
//                 </span>
//                 {props.data ? props.data.address : "loading"}
//               </p>
//             </div>
//             <div className="contact-item">
//               <p>
//                 <span>
//                   <i className="fa fa-phone"></i> Phone
//                 </span>{" "}
//                 {props.data ? props.data.phone : "loading"}
//               </p>
//             </div>
//             <div className="contact-item">
//               <p>
//                 <span>
//                   <i className="fa fa-envelope-o"></i> Email
//                 </span>{" "}
//                 {props.data ? props.data.email : "loading"}
//               </p>
//               <p>
//                 <span>
//                   <i className="fa fa-envelope-o"></i> Director Email
//                 </span>{" "}
//                 {props.data ? props.data.Email : "loading"}
//               </p>
//             </div>
//           </div>
//           <div className="col-md-12">
//             <div className="row">
//               {/* <div className="social">
//                 <ul>
//                   <li>
//                     <a href={props.data ? props.data.facebook : "/"}>
//                       <i className="fa fa-facebook"></i>
//                     </a>
//                   </li>
//                   <li>
//                     <a href={props.data ? props.data.twitter : "/"}>
//                       <i className="fa fa-twitter"></i>
//                     </a>
//                   </li>
//                   <li>
//                     <a href={props.data ? props.data.youtube : "/"}>
//                       <i className="fa fa-youtube"></i>
//                     </a>
//                   </li>
//                 </ul>
//               </div> */}
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };








import { useState } from "react";
import emailjs from "emailjs-com";
import React from "react";
import { useTranslation } from 'react-i18next';

const initialState = {
  name: "",
  email: "",
  message: "",
};

export const Contact = (props) => {
  const [{ name, email, message }, setState] = useState(initialState);
  const [feedback, setFeedback] = useState(""); // State to show feedback message
  const { t } = useTranslation(); // Translation hook

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const clearState = () => setState({ ...initialState });

  const handleSubmit = (e) => {
    e.preventDefault();
    setFeedback(""); // Reset feedback message on submission

    // Send the email using EmailJS
    emailjs
      .send(
        "service_xqzvbw5", // Correct Service ID
        "template_hao0qy9", // Correct Template ID
        {
          from_name: name,
          from_email: email,
          to_email: "ceo.barakzai@ukpetroleum.co.uk", // Replace with recipient's email
          message: message,
        },
        "IPK2zT8ebCC1eW4AK" // Correct User ID
      )
      .then(
        (response) => {
          setFeedback(t('contact.feedbackSuccess')); // Success message
          clearState(); // Clear the form fields after successful submission
        },
        (error) => {
          setFeedback(t('contact.feedbackError')); // Error message
          console.error("Email sending error:", error.text);
        }
      );
  };

  return (
    <div>
      <div
        id="contact"
        style={{
          background: "radial-gradient(circle 878px at 52.2% 62.3%, rgba(20, 156, 73, 1) 0%, rgba(8, 31, 26, 1) 90%)",
          color: "#ffffff",
          padding: "50px 0",
        }}
      >
        <div className="container">
          <div className="col-md-8">
            <div className="row">
              <div className="section-title">
                <h2>{t('contact.title')}</h2>
                <p>{t('contact.description')}</p>
              </div>
              {feedback && <p style={{ color: "#fff" }}>{feedback}</p>} {/* Feedback Message */}
              <form name="sentMessage" onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="name"
                        name="name"
                        className="form-control"
                        placeholder={t('contact.namePlaceholder')}
                        required
                        onChange={handleChange}
                        value={name}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="email"
                        id="email"
                        name="email"
                        className="form-control"
                        placeholder={t('contact.emailPlaceholder')}
                        required
                        onChange={handleChange}
                        value={email}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <textarea
                    name="message"
                    id="message"
                    className="form-control"
                    rows="4"
                    placeholder={t('contact.messagePlaceholder')}
                    required
                    onChange={handleChange}
                    value={message}
                  ></textarea>
                </div>
                <button type="submit" className="btn btn-custom btn-lg">
                  {t('contact.sendMessage')}
                </button>
              </form>
            </div>
          </div>
          <div className="col-md-3 col-md-offset-1 contact-info">
            <div className="contact-item">
              <h3>{t('contact.contactInfo')}</h3>
              <p>
                <span>
                  <i className="fa fa-map-marker"></i> {t('contact.address')}
                </span>
                {props.data ? props.data.address : "loading"}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-phone"></i> {t('contact.phone')}
                </span>{" "}
                {props.data ? props.data.phone : "loading"}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-envelope-o"></i> {t('contact.email')}
                </span>{" "}
                {props.data ? props.data.email : "loading"}
              </p>
              <p>
                <span>
                  <i className="fa fa-envelope-o"></i> {t('contact.directorEmail')}
                </span>{" "}
                {props.data ? props.data.Email : "loading"}
              </p>
            </div>
          </div>
          <div className="col-md-12">
            <div className="row">
              {/* Add your social links here */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
