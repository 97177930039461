// import React, { useState } from "react";

// export const Header = (props) => {
//   const [isHovered, setIsHovered] = useState(false);

//   const buttonStyle = {
//     background:
//       "radial-gradient(circle 878px at 52.2% 62.3%, rgba(20, 156, 73, 1) 0%, rgba(8, 31, 26, 1) 90%)",
//     color: "#fff",
//     fontSize: "20px",
//     fontWeight: "bold",
//     border: "none",
//     padding: "10px 20px",
//     borderRadius: "5px",
//     textDecoration: "none",
//     transition: "transform 0.3s ease",
//     transform: isHovered ? "scale(1.1)" : "scale(1)",
//   };

//   const scrollToFeatures = () => {
//     const section = document.getElementById("features");
//     if (section) {
//       console.log("Section found. Scrolling...");
//       section.scrollIntoView({ behavior: "smooth", block: "start" });
//     } else {
//       console.error("Section not found: features");
//     }
//   };

//   return (
//     <header id="header">
//       <div className="intro">
//         <div className="overlay">
//           <div className="container">
//             <div className="row">
//               <div className="col-md-8 col-md-offset-2 intro-text text-white">
//                 <img
//                   src="/img/logo.png"
//                   alt="Company Logo"
//                   style={{ width: "200px", marginBottom: "20px" }}
//                 />
//                 <h2 style={{ color: "#f0c419" }}>
//                   {props.data ? props.data.title : "Loading"}
//                   <span></span>
//                 </h2>
//                 <a
//                   href="#features"
//                   className="btn btn-lg page-scroll"
//                   style={{
//                     color: isHovered ? "#FFD700" : "#FFD700", 
//                     backgroundColor: isHovered ? "#006400" : "#008000", 
//                     border: "none",
//                     borderRadius: "8px",
//                     padding: "10px 20px",
//                     fontSize: "18px",
//                     transition: "background-color 0.3s ease, color 0.3s ease", 
//                     cursor: "pointer",
//                   }}
//                   onMouseEnter={() => setIsHovered(true)}
//                   onMouseLeave={() => setIsHovered(false)}
//                   onClick={scrollToFeatures}
//                 >
//                   Learn More
//                 </a>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </header>
//   );
// };





// import React, { useState } from "react";
// import { useTranslation } from 'react-i18next';

// export const Header = (props) => {
//   const { t } = useTranslation(); // Hook to get translation function
//   const [isHovered, setIsHovered] = useState(false);

//   const buttonStyle = {
//     background:
//       "radial-gradient(circle 878px at 52.2% 62.3%, rgba(20, 156, 73, 1) 0%, rgba(8, 31, 26, 1) 90%)",
//     color: "#fff",
//     fontSize: "20px",
//     fontWeight: "bold",
//     border: "none",
//     padding: "10px 20px",
//     borderRadius: "5px",
//     textDecoration: "none",
//     transition: "transform 0.3s ease",
//     transform: isHovered ? "scale(1.1)" : "scale(1)",
//   };

//   const scrollToFeatures = () => {
//     const section = document.getElementById("features");
//     if (section) {
//       console.log("Section found. Scrolling...");
//       section.scrollIntoView({ behavior: "smooth", block: "start" });
//     } else {
//       console.error("Section not found: features");
//     }
//   };

//   return (
//     <header id="header">
//       <div className="intro">
//         <div className="overlay">
//           <div className="container">
//             <div className="row">
//               <div className="col-md-8 col-md-offset-2 intro-text text-white">
//                 <img
//                   src="/img/logo.png"
//                   alt="Company Logo"
//                   style={{ width: "200px", marginBottom: "20px" }}
//                 />
//          <h2 style={{ color: "#f0c419" }}>
//       {props.data ? t('header.title') : "Loading"} {/* Use translation */}
//     </h2>
//                 <a
//                   href="#features"
//                   className="btn btn-lg page-scroll"
//                   style={{
//                     color: isHovered ? "#FFD700" : "#FFD700", 
//                     backgroundColor: isHovered ? "#006400" : "#008000", 
//                     border: "none",
//                     borderRadius: "8px",
//                     padding: "10px 20px",
//                     fontSize: "18px",
//                     transition: "background-color 0.3s ease, color 0.3s ease", 
//                     cursor: "pointer",
//                   }}
//                   onMouseEnter={() => setIsHovered(true)}
//                   onMouseLeave={() => setIsHovered(false)}
//                   onClick={scrollToFeatures}
//                 >
//                   {t('learnMore')} {/* Use translation */}
//                 </a>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </header>
//   );
// };




import React, { useState } from "react";
import { useTranslation } from 'react-i18next';

export const Header = (props) => {
  const { t } = useTranslation(); // Hook to get translation function
  const [isHovered, setIsHovered] = useState(false);

  // Button style that changes on hover
  const buttonStyle = {
    background:
      "radial-gradient(circle 878px at 52.2% 62.3%, rgba(20, 156, 73, 1) 0%, rgba(8, 31, 26, 1) 90%)",
    color: "#fff",
    fontSize: "20px",
    fontWeight: "bold",
    border: "none",
    padding: "10px 20px",
    borderRadius: "5px",
    textDecoration: "none",
    transition: "transform 0.3s ease",
    transform: isHovered ? "scale(1.1)" : "scale(1)",
  };

  const scrollToFeatures = () => {
    const section = document.getElementById("features");
    if (section) {
      console.log("Section found. Scrolling...");
      section.scrollIntoView({ behavior: "smooth", block: "start" });
    } else {
      console.error("Section not found: features");
    }
  };

  return (
    <header id="header">
      <div className="intro">
        <div className="overlay">
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-md-offset-2 intro-text text-white">
                <img
                  src="/img/logo.png"
                  alt="Company Logo"
                  style={{ width: "200px", marginBottom: "20px" }}
                />
                <h2 style={{ color: "#f0c419" }}>
                  {props.data ? t('title') : "Loading"} {/* Use translation */}
                </h2>
                <a
                  href="#features"
                  className="btn btn-lg page-scroll"
                  style={{
                    color: isHovered ? "#FFD700" : "#FFD700", 
                    backgroundColor: isHovered ? "#006400" : "#008000", 
                    border: "none",
                    borderRadius: "8px",
                    padding: "10px 20px",
                    fontSize: "18px",
                    transition: "background-color 0.3s ease, color 0.3s ease", 
                    cursor: "pointer",
                  }}
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                  onClick={scrollToFeatures}
                >
                  {t('learnMore')} {/* Use translation */}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
