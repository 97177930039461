// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Import translation JSON files
import enTranslation from './locales/en/translatio.json';
import deTranslation from './locales/de/translatio.json';
import frTranslation from './locales/fr/translatio.json';
import viTranslation from './locales/vi/translatio.json';
import zhTranslation from './locales/zh/translatio.json';
import arTranslation from './locales/ar/translatio.json';
import faTranslation from './locales/fa/translatio.json';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: enTranslation },
      de: { translation: deTranslation },
      fr: { translation: frTranslation },
      vi: { translation: viTranslation },
      zh: { translation: zhTranslation },
      ar: { translation: arTranslation },
      fa: { translation: faTranslation },
    },
    lng: 'en', // default language
    fallbackLng: 'en', // fallback language
    interpolation: { escapeValue: false }, // React already does escaping
  });

export default i18n;
